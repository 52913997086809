import { PARTIAL_AUTHENTICATION_ERROR_CODE } from '@constants/errors'
import { useRouter } from 'next/router'
import { SIGNIN } from '@constants/routes'
import { sessionErrorSelector } from '@features/error/selector'
import { resetAllErrors } from '@features/error/slice'
import { useSelector, useDispatch } from 'react-redux'
import { isPartiallyAuthenticatedSelector } from '@features/context/selector'
import { isAutoLoginSelector } from '@features/user/selector'
interface RedirectParamsForPartialAuthError {
  redirectUrl: string
  hasErrorCode: boolean
}

export const useRequireFullAuth = () => {
  const dispatch = useDispatch()
  const router = useRouter()
  const { errorCode } = useSelector(sessionErrorSelector)
  const isPartiallyAuthenticated = useSelector(isPartiallyAuthenticatedSelector)
  const isAutologin = useSelector(isAutoLoginSelector)

  const redirectToSignIn = (redirectUrl: string): void => {
    router.push({
      pathname: `/${SIGNIN}`,
      query: {
        redirect: redirectUrl,
        isPartiallyAuthenticated: isAutologin ? 0 : 1,
      },
    })
  }

  /**
   * @name Redirects the user to the sign in page if the user is partially authenticated or the session is autologin
   * @param param.redirectUrl - The URL to redirect to after the user is authenticated
   * @param param.noErrorCodeRequired - If true, the user will be redirected to the redirectUrl without checking the error code but forbiddeen urls- example: myaccount forbidden links :
   * @returns
   */

  const handleNotAllowedWithPartialAuth = ({ redirectUrl, hasErrorCode }: RedirectParamsForPartialAuthError): void => {
    if (!redirectUrl) return

    if (
      (isPartiallyAuthenticated && !hasErrorCode && redirectUrl !== '/') ||
      (errorCode === PARTIAL_AUTHENTICATION_ERROR_CODE && isPartiallyAuthenticated) ||
      isAutologin
    ) {
      dispatch(resetAllErrors())
      redirectToSignIn(redirectUrl)
    }
  }

  return {
    handleNotAllowedWithPartialAuth,
  }
}
